var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[_c('date-picker',{attrs:{"label":"Desde"},on:{"save":function($event){_vm.from = $event}}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[_c('date-picker',{attrs:{"label":"Hasta"},on:{"save":function($event){_vm.to = $event}}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"label":"Profesor","items":_vm.users,"clearable":""},on:{"change":function($event){return _vm.filterBy()}},model:{value:(_vm.user_id),callback:function ($$v) {_vm.user_id=$$v},expression:"user_id"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"Materia","items":_vm.subjects,"clearable":""},on:{"change":function($event){return _vm.filterBy()}},model:{value:(_vm.subject_id),callback:function ($$v) {_vm.subject_id=$$v},expression:"subject_id"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"Examen","items":_vm.exams,"clearable":""},on:{"change":function($event){return _vm.filterBy()}},model:{value:(_vm.exam_id),callback:function ($$v) {_vm.exam_id=$$v},expression:"exam_id"}})],1)],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.examsDone,"loading":_vm.loading,"loading-text":"Cargando... por favor espere","footer-props":{
        'items-per-page-text': 'Exámenes realizados por página',
      }},scopedSlots:_vm._u([{key:"item.answer_details",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.result(item))+" ")]}},{key:"item.user",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.name)+" "+_vm._s(item.user.paternal_name)+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.showDialog({answerId: item.id})}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}])})],1),_c('exam-answered',{attrs:{"show":_vm.dialog,"answerDetail":_vm.answerDetails},on:{"close":function($event){_vm.dialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }